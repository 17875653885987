import styles from "./support.module.scss";
import Image from "next/image";
import Email from "../../public/images/email_image_small.png";
import Phone from "../../public/images/phone_image_small.png";
import RightArrow from "../../public/images/right_arrow_black.png";
import Link from "next/link";
import { useRouter } from "next/router";

export default function Support({ categories }) {
  const router = useRouter();
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.colWrapper}>
          <div className={styles.left}>
            <div className={styles.link}>
              <span>support home</span>
            </div>
            <div className={styles.header}>
              <h1>welcome to loop support.</h1>
            </div>

            <div className={styles.subText}>
              <p>
                find what you need below, or contact us directly at any time.
              </p>
            </div>

            <div className={styles.itemWrapper}>
              {categories.map((category) => (
                <div key={category._id} className={styles.item}>
                  <Link href={`/support/category/${category.slug.current}`}>
                    <a>
                      <h2>{category.title}</h2>
                      <div className={styles.circle}>
                        <div className={styles.arrow}>
                          <Image src={RightArrow} alt="Background Image" />
                        </div>
                      </div>
                    </a>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.help}>
              <div className={styles.overlay}>
                <div className={styles.title}>
                  <h2>Need additional help?</h2>
                </div>
                {/* <Image src={PromoImage} alt="Promo Image" /> */}
                <div className={styles.button}>
                  <div className={styles.content}>
                    <div className={styles.image}>
                      <Image src={Phone} alt="Phone Image" />
                    </div>
                    <div className={styles.text}>
                      <p> 888-991-LOOP</p>
                    </div>
                  </div>
                </div>
                <div className={styles.button}>
                  <div className={styles.content}>
                    <div className={styles.image}>
                      <Image src={Email} alt="Email Image" />
                    </div>
                    <div className={styles.text}>
                      <p> support@loop.tv</p>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.underlay}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
