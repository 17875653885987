import client from "../../client";
import Head from "next/head";
import Support from "../../components/support/support.js";
import GradientBG from "../../components/layout/gradientBackground";
import {useEffect} from "react";
import { fbEvent } from '@rivercode/facebook-conversion-api-nextjs';

function Page({ categorydata }) {

  useEffect(() => {

    fbEvent({
      eventName: 'PageView', // ViewContent, AddToCart, InitiateCheckout or Purchase
      products: [{
        sku: 'player',
        quantity: 1,
      }],
      value: 100,
      currency: 'USD',
      enableStandardPixel: true // default false (Require Facebook Pixel to be loaded, see step 2)
    });
  
  }, [])
  return (
    <div className="container">
      <Head>
        <title>loop.tv | cash rewards for your business</title>
        <meta name="description" content="loop.tv" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href="https://www.loop.tv/support" />
      </Head>
      <GradientBG>
        <Support categories={categorydata} />
      </GradientBG>
    </div>
  );
}

export async function getStaticProps() {
  const categorydata = await client.fetch('*[_type == "supportCategory"]{...} | order(title asc)');
  return {
    props: {
      categorydata,
    },
  };
}

export default Page;
